import * as React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import PostTemplate from '../components/templates/PostTemplate';

type Props = {
  data: any;
  location: any;
  pageContext: {
    id: string;
    prev: any;
    next: any;
    numPage: number;
  };
};

const Post = ({ data, pageContext, location }: Props) => {

  const { wordpressPost: post } = data;
  const { prev, next, id } = pageContext;

  return (
    <Layout>
      <SEO
        title={`${post.title}`}
        permalink={post.link}
        description={post.excerpt}
        article
        date={post.date}
        modified={post.modified}
        image={post.featured_media && post.featured_media.source_url}
      />
      <PostTemplate
        location={location}
        id={id}
        content={post.content}
        categories={post.categories}
        tags={post.tags}
        title={post.title}
        date={post.date}
        modified={post.modified}
        author={post.author}
        featuredMedia={post.featured_media}
        permalink={post.link.replace('wp-blog.yuhiisk.com', 'blog.yuhiisk.com')}
        prev={prev}
        next={next}
        relatedPosts={post.related_posts}
      />
    </Layout>
  )
}


export default Post;

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date
    modified
    title
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      link
      date
      modified
      featured_media {
        source_url
      }
      categories {
        name
        slug
      }
      tags {
        name
        slug
      }
      author {
        name
        description
        slug
        avatar_urls {
          wordpress_96
        }
      }
      related_posts {
        title
        date
        slug
        image
      }
    }
  }
`
